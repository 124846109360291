import { CloseOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

function CustomModal({ data, URL, children, onCancel, footer }) {
  const downloadFile = () => {
    window.location.href = URL
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 12345,
        display: 'block',
      }}
    >
      <div>
        <div id="preview-header">
          <div
            style={{
              height: '54px',
              backgroundColor: 'black',
              paddingTop: '9px',
            }}
          >
            <div className="d-flex justify-content-between px-3">
              <div style={{ alignSelf: 'center' }}>
                <span style={{ color: 'white', fontSize: 14 }}>{data.name}</span>
              </div>
              <div style={{ alignSelf: 'center' }}>
                <Button
                  onClick={downloadFile}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                  }}
                >
                  <DownloadOutlined
                    style={{
                      fontSize: 18,
                      verticalAlign: 'middle',
                      color: 'white',
                    }}
                  />
                </Button>
                <Button
                  onClick={onCancel}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    border: 'none',
                  }}
                >
                  <CloseOutlined
                    style={{
                      fontSize: 18,
                      verticalAlign: 'middle',
                      color: 'white',
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          aria-hidden
          id="preview-body"
          style={{ height: '80vh', width: '100vw', margin: 'auto', backgroundColor: 'transparent' }}
        >
          {children}
        </div>
        <div
          className="preview-footer"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {footer}
        </div>
      </div>
    </div>
  )
}

export default CustomModal
