import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'
import Feedback from './Feedback'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { openFeedback: false }
  }

  render() {
    const {
      t,
      settings: { isContentNoMaxWidth },
    } = this.props
    const { openFeedback } = this.state
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <footer id="footer" className="section-bg">
          <div className="footer-top text-center">
            <div className="col-12">
              <div className="copyright">
                <div className="social-links sns-icon">
                  <a
                    href="https://www.facebook.com/ACworks2011/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="facebook"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href="https://twitter.com/ACworks2011"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="twitter"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="footer-info">
                    {/* <h3><img style="width: 160px;margin-top: -8px;" src="https://ac-data.info/assets/frontend/img/logo_footer.png" /></h3> */}
                    <a href="https://ac-data.info">
                      <img
                        src="/resources/images/ACdata_white.png"
                        alt="ACdata"
                        className="img-fluid"
                        style={{ width: 150 }}
                      />
                    </a>
                  </div>
                  <div className="footer-links">
                    <ul>
                      <li>
                        <a href="https://ac-data.info/guide">{t('guide_blog')}</a>
                      </li>
                      <li>
                        <a href="https://help.freebie-ac.jp/category/35/ACdata">{t('faqs')}</a>
                      </li>
                      <li>
                        <a href="https://ac-data.info/terms">{t('terms')}</a>
                      </li>
                      <li>
                        <a href="https://ac-data.info/personal-policy">{t('personal_policy')}</a>
                      </li>
                      <li>
                        <a href="https://ac-data.info/policy">{t('policy')}</a>
                      </li>
                      {/*  <li><a href="https://help.freebie-ac.jp/" target="_blank">FAQ</a></li> */}
                      <li>
                        <a href="https://acworks.co.jp/" target="_blank" rel="noreferrer noopener">
                          {t('our_company')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="copyright">
                <div className="social-links" style={{ paddingBottom: 20 }}>
                  <Button
                    style={{
                      fontSize: '80%',
                      verticalAlign: 'middle',
                      width: '160px',
                      height: '36px',
                      background: '#4d4d4d',
                      border: '1px solid #4d4d4d',
                      color: '#b9b9b9',
                    }}
                    className="ant-btn"
                    onClick={() => {
                      // this.setState({ openFeedback: true })
                      window.open('https://help.freebie-ac.jp', '_blank')
                    }}
                  >
                    <i
                      style={{ fontSize: '18px', paddingRight: '2px' }}
                      className="fa fa-commenting-o"
                    />
                    {t('feedback')}
                  </Button>
                </div>
                {/* Modal */}
                <Feedback
                  isVisible={openFeedback}
                  toggle={() => {
                    this.setState({ openFeedback: false })
                  }}
                />
              </div>
              <div className="credits">{t('copyright')}</div>
            </div>
          </div>
        </footer>
      </div>
      // </div>
    )
  }
}

export default withTranslation()(Footer)
