import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Avatar, Dropdown, Menu } from 'antd'
import Img from 'react-image'
import { LoginOutlined } from '@ant-design/icons'
import LanguageSwitcher from './LanguageSwitcher'
import style from './style.module.scss'
import { refreshFiles } from '../../../redux/files/actionCreators'

class TopBar extends React.Component {
  state = {
    isOpenUploadModal: false,
    isOpenShareModal: false,
    isLoggedIn: false,
  }

  componentDidMount() {
    if (
      localStorage.getItem('global_session_id') !== null &&
      localStorage.getItem('global_session_id') !== undefined &&
      localStorage.getItem('global_session_id') !== ''
    ) {
      // console.log(localStorage.getItem('global_session_id'))
      this.setIsLoggedIn(true)
    } else {
      localStorage.removeItem('sessionUserAvatar')
      localStorage.removeItem('sessionUserEmail')
      localStorage.removeItem('sessionUserName')
      localStorage.removeItem('sessionUserId')
      localStorage.removeItem('sessionUserPremium')
      // console.log("check login")
      this.setIsLoggedIn(false)
    }
  }

  // componentWillUpdate() {
  //   if (
  //     localStorage.getItem('global_session_id') !== null &&
  //     localStorage.getItem('global_session_id') !== undefined &&
  //     localStorage.getItem('global_session_id') !== ''
  //   ) {
  //     this.setIsLoggedIn(true)
  //   } else {
  //     this.setIsLoggedIn(false)
  //   }
  // }

  setOpenUploadModal = isOpenUploadModal => {
    this.setState({ isOpenUploadModal })
  }

  setOpenShareModal = isOpenShareModal => {
    this.setState({ isOpenShareModal })
  }

  setIsLoggedIn = isLoggedIn => {
    this.setState({ isLoggedIn })
  }

  handleClose = () => {
    const { resetFileList } = this.props
    resetFileList(true)
  }

  render() {
    const {
      isOpenUploadModal,
      isOpenShareModal,
      selectedFiles,
      zippedFile,
      isLoggedIn,
    } = this.state
    const { t } = this.props
    const handleZippedFile = value => {
      this.setState({
        zippedFile: value,
      })
    }

    const userAvatar = localStorage.getItem('sessionUserAvatar')
    const userName = localStorage.getItem('sessionUserName')
    const userEmail = localStorage.getItem('sessionUserEmail')
    const menu = (
      <Menu style={{ top: 20 }}>
        <Menu.Item>
          <Button
            type="link"
            style={{
              color: 'black',
              padding: '0 10px',
            }}
            href="https://accounts.ac-illust.com/?serviceURL=https://ac-data.info"
          >
            {t('my_page')}
          </Button>
        </Menu.Item>
        <LanguageSwitcher isLoggedIn={isLoggedIn} />
        <Menu.Item style={{ textAlign: 'center', padding: '0 10px' }}>
          <Button
            type="link"
            style={{
              color: 'black',
              padding: '0 10px',
            }}
            onClick={() => {
              const globalSessionToken = localStorage.getItem('global_session_id')
              localStorage.removeItem('global_session_id')
              sessionStorage.clear()
              localStorage.clear()
              const link = document.createElement('a')
              link.href = `https://accounts.ac-illust.com/logout?sessionID=${globalSessionToken}&serviceURL=https://ac-data.info`
              document.body.appendChild(link)
              return link.click()
            }}
          >
            {t('logout_button')}
          </Button>
        </Menu.Item>
      </Menu>
    )

    const login = localStorage.getItem('login')
    return (
      <div className={style.topbar}>
        <div
          className="mr-auto ml-auto justify-content-between d-flex"
          style={{
            width: '75vw',
            maxWidth: 1400,
          }}
        >
          {/* <div className="mr-auto d-none d-md-block">
          <button
            type="button"
            className="btn btn-primary btn-with-addon mr-auto d-none d-md-block"
            onClick={() => this.setOpenUploadModal(true)}
          >
            <span className="btn-addon">
              <i className="btn-addon-icon fe fe-upload" />
            </span>
            {t('upload_button')}
          </button>
          <Modal
            title={t('upload_files_title')}
            centered
            width={900}
            height={600}
            visible={isOpenUploadModal}
            onOk={() => this.setOpenUploadModal(false)}
            onCancel={() => this.setOpenUploadModal(false)}
            footer={null}
            destroyOnClose
            afterClose={this.handleClose}
          >
            <Dropzone
              disableShareByURL
              handleZippedFile={handleZippedFile}
              onClickShare={files => {
                // console.log('in topbar ', filenames)
                this.setState({ selectedFiles: files })
                this.setOpenShareModal(true)
              }}
            />
            <ShareModal
              selectedFiles={selectedFiles}
              shareType="files"
              zippedFile={zippedFile}
              isVisible={isOpenShareModal}
              toggle={() => this.setOpenShareModal(false)}
            />
          </Modal>
        </div> */}
          <div className="">
            <header id="header">
              <div className="container">
                <div className="logo float-left mr-md-4 mr-auto header-logo font-size-23">
                  <a href="/">
                    <img
                      src="/resources/images/ACdata_logo.png"
                      alt="ACdata"
                      className="img-fluid ac-logo-text"
                    />
                    <img
                      src="/resources/images/ACdata_logo.png"
                      alt="ACdata"
                      className="img-fluid ac-logo-notext"
                    />
                  </a>
                  <span
                    className="ac-logo-text-description"
                    style={{
                      marginTop: 10,
                      display: 'block',
                      // maxWidth: 290,
                      fontSize: 14,
                      minWidth: 111,
                    }}
                  >
                    {t('logo_description')}
                  </span>
                </div>
              </div>
            </header>
          </div>
          <div className="">
            <nav
              className="main-nav float-right d-inline-block font-weight-bold text-nowrap"
              style={{ marginTop: 20 }}
            >
              {login !== '1' && (
                <ul>
                  <li>
                    <LanguageSwitcher isLoggedIn={false} />
                  </li>
                </ul>
              )}
              {login === '1' && (
                <ul>
                  <li className="header-menu-item">
                    <Button
                      className="btn-hover"
                      style={{
                        padding: '2px 10px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        marginRight: 10,
                        marginTop: 15,
                        display: 'inline-block',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                      href="https://ac-data.info/guide"
                    >
                      {t('guide_blog')}
                    </Button>
                  </li>
                  <li className="header-menu-item">
                    <Button
                      className="btn-hover"
                      style={{
                        padding: '2px 10px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        marginRight: 10,
                        marginTop: 15,
                        display: 'inline-block',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                      onClick={() =>
                        window.open('https://help.freebie-ac.jp/category/35/ACdata', '_blank')
                      }
                    >
                      {t('faqs')}
                    </Button>
                  </li>
                  <li className="header-menu-item">
                    <Button
                      className="btn-hover"
                      style={{
                        padding: '2px 10px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        marginRight: 10,
                        marginTop: 15,
                        display: 'inline-block',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                      href="https://ac-data.info/terms"
                    >
                      {t('terms')}
                    </Button>
                  </li>
                  <li className="header-menu-item">
                    <Button
                      className="btn-hover"
                      style={{
                        padding: '2px 10px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        marginRight: 10,
                        marginTop: 15,
                        display: 'inline-block',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                      href="https://ac-data.info/policy"
                    >
                      {t('policy')}
                    </Button>
                  </li>
                  <li className="header-menu-item">
                    <Button
                      className="btn-hover"
                      style={{
                        padding: '2px 10px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        marginRight: 10,
                        marginTop: 15,
                        display: 'inline-block',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                      onClick={() => window.open('https://acworks.co.jp/', '_blank')}
                    >
                      {t('our_company')}
                    </Button>
                  </li>
                  {!isLoggedIn && (
                    <>
                      <li>
                        <Button
                          className="btn-hover"
                          style={{
                            padding: '2px 10px',
                            width: 85,
                            whiteSpace: 'nowrap',
                            height: 35,
                            marginRight: 10,
                            marginTop: 15,
                            fontWeight: 'bold',
                            display: 'inline-block',
                            border: 'none',
                            boxShadow: 'none',
                          }}
                          href="https://accounts.ac-illust.com/signup?serviceURL=https://ac-data.info"
                        >
                          {t('signup_button')}
                        </Button>
                      </li>
                      <li>
                        <Button
                          type="primary"
                          // className="btn-hover"
                          style={{
                            color: 'white',
                            padding: '2px 10px',
                            width: 85,
                            whiteSpace: 'nowrap',
                            height: 35,
                            marginRight: 10,
                            marginTop: 15,
                            fontWeight: 'bold',
                            display: 'inline-block',
                          }}
                          href="https://accounts.ac-illust.com/login?serviceURL=https://ac-data.info"
                        >
                          {/* <i className="fa fa-sign-in mr-1" aria-hidden="true" /> */}
                          {t('login_button')}
                        </Button>
                      </li>
                      <li className="hide-phone-component">
                        <LanguageSwitcher isLoggedIn={isLoggedIn} />
                      </li>
                    </>
                  )}
                  {isLoggedIn && (
                    <li>
                      <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomLeft"
                        overlayStyle={{ top: 20, color: 'red' }}
                      >
                        <Button
                          className="btn-hover"
                          style={{
                            textAlign: 'left',
                            border: 0,
                            height: 70,
                            width: 250,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Img
                              src={[userAvatar]}
                              style={{ maxHeight: 50, borderRadius: '50%', float: 'left' }}
                              unloader={
                                <Avatar
                                  shape="circle"
                                  style={{ float: 'left' }}
                                  size={50}
                                  src="/resources/images/avatar_default.png"
                                />
                              }
                            />
                            <span style={{ paddingLeft: 10, paddingTop: 5 }}>{userName}</span>
                          </div>
                          <span
                            style={{
                              paddingLeft: 10,
                              fontSize: 12,
                              display: 'flex',
                              marginTop: 20,
                            }}
                          >
                            {userEmail}
                          </span>
                        </Button>
                      </Dropdown>
                    </li>
                  )}
                </ul>
              )}
            </nav>
          </div>
        </div>
        <style jsx>
          {`
            @media (max-width: 1573px) {
              .header-menu-item {
                display: none;
              }
              .ac-logo-text-description {
                max-width: 100%;
              }
            }
            @media (max-width: 1178px) {
              .ac-logo-text-description {
                max-width: 285px;
              }
            }
          `}
        </style>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  resetFileList: flag => dispatch(refreshFiles(flag)),
})
export default connect(null, mapDispatchToProps)(withTranslation()(TopBar))
