export default async function getMenuData() {
  return [
    {
      title: 'my_files',
      key: 'files',
      icon: 'fe fe-folder',
      count: 6,
      url: '/dashboard/files',
    },
    {
      title: 'terms',
      key: 'terms',
      icon: 'fe fe-file-text',
      count: 6,
      url: '/dashboard/terms',
    },
  ]
}
