const { verifyJwtToken } = require('./jwt_verify')
require('dotenv').config()

const ssoServerJWTURL = 'https://accounts.freebieac.com'

let isVerifying = false

// const loadUserMiddleware = ({ dispatch, getState }) => next => async action => {
//   const { ssoToken } = getState().router.location.query
//   const redirectURL = `https://ac-data.info${getState().router.location.pathname}`
//   // const redirectURL = `https://ac-data.info${getState().router.location.pathname}`
//   try {
//     if (isVerifying) {
//       console.log('isVerifyingggggggg')
//       return next(action)
//     }
//     if (ssoToken !== null && ssoToken !== undefined) {
//       console.log('redirectURLLLLLLLL')
//       isVerifying = true
//       // to remove the ssoToken in query parameter redirect.
//       await fetch(`${ssoServerJWTURL}/verifytoken?ssoToken=${ssoToken}`, {
//         headers: {
//           Authorization: 'Bearer ogJO57mAMCyhqjILvc8RCmaTv1rf',
//         },
//       })
//         .then(ress => ress.json())
//         .then(async ({ token }) => {
//           const decoded = await verifyJwtToken(token)
//           sessionStorage.setItem('global_session_id', decoded.global_session_id)
//           return decoded.global_session_id
//         })
//         .then(async globalSessionToken => {
//           console.log('insideeeeeeeeeeee get-session-user')
//           await fetch(
//             `${ssoServerJWTURL}/get-session-user?globalSessionToken=${globalSessionToken}`,
//           )
//             .then(ress => ress.json())
//             .then(response => {
//               console.log('insideeeeeeeeeeee get-session-user res ', response)
//               const ssoUserLoggedin = response.sessionUser ? response.sessionUser : null
//               if (!ssoUserLoggedin) {
//                 sessionStorage.clear()
//                 const link = document.createElement('a')
//                 link.href = '/'
//                 document.body.appendChild(link)
//                 return link.click()
//               }
//               sessionStorage.setItem('sessionUserAvatar', response.sessionUser.avatar)
//               sessionStorage.setItem('sessionUserEmail', response.sessionUser.email)
//               sessionStorage.setItem('sessionUserName', response.sessionUser.nick_name)
//               sessionStorage.setItem('sessionUserId', response.sessionUser.user_id)
//               sessionStorage.setItem('sessionUserPremium', response.sessionUser.premium_info)
//               return true
//             })
//             .catch(err => {
//               console.log({ message: err.message, err, location: 'get-session-user' })
//               return next(action)
//             })
//         })
//         .then(() => {
//           const link = document.createElement('a')
//           link.href = redirectURL
//           document.body.appendChild(link)
//           return link.click()
//         })
//         .catch(err => {
//           console.log({ message: err.message, err, location: 'verifytoken' })
//           return next(action)
//         })
//     }
//   } catch (err) {
//     console.log({ message: err.message, err, location: 'try catch' })
//     return next(action)
//   }
//   return next(action)
// }

const loadUserMiddleware = ({ dispatch, getState }) => next => async action => {
  const { ssoToken } = getState().router.location.query
  const redirectURL = `https://ac-data.info${getState().router.location.pathname}`
  // const redirectURL = `https://ac-data.info${getState().router.location.pathname}`
  try {
    if (isVerifying) {
      console.log('isVerifyingggggggg')
      return next(action)
    }
    if (ssoToken !== null && ssoToken !== undefined) {
      console.log('redirectURLLLLLLLL')
      isVerifying = true
      // to remove the ssoToken in query parameter redirect.
      try {
        let ress = await fetch(`${ssoServerJWTURL}/verifytoken?ssoToken=${ssoToken}`, {
          headers: {
            Authorization: 'Bearer ogJO57mAMCyhqjILvc8RCmaTv1rf',
          },
        })
        const { token } = await ress.json()
        const decoded = await verifyJwtToken(token)
        sessionStorage.setItem('global_session_id', decoded.global_session_id)
        const globalSessionToken = decoded.global_session_id

        try {
          console.log('insideeeeeeeeeeee get-session-user')
          ress = await fetch(
            `${ssoServerJWTURL}/get-session-user?globalSessionToken=${globalSessionToken}`,
          )
          const response = await ress.json()
          console.log('insideeeeeeeeeeee get-session-user res ', response)
          const ssoUserLoggedin = response.sessionUser ? response.sessionUser : null
          if (!ssoUserLoggedin) {
            sessionStorage.clear()
            const link = document.createElement('a')
            link.href = '/'
            document.body.appendChild(link)
            return link.click()
          }
          sessionStorage.setItem('sessionUserAvatar', response.sessionUser.avatar)
          sessionStorage.setItem('sessionUserEmail', response.sessionUser.email)
          sessionStorage.setItem('sessionUserName', response.sessionUser.nick_name)
          sessionStorage.setItem('sessionUserId', response.sessionUser.user_id)
          sessionStorage.setItem('sessionUserPremium', response.sessionUser.premium_info)
        } catch (err) {
          console.log({ message: err.message, err, location: 'get-session-user' })
          return next(action)
        }

        const link = document.createElement('a')
        link.href = redirectURL
        document.body.appendChild(link)
        return link.click()
      } catch (err) {
        console.log({ message: err.message, err, location: 'verifytoken' })
        return next(action)
      }
    }
  } catch (err) {
    console.log({ message: err.message, err, location: 'try catch' })
    return next(action)
  }
  return next(action)
}

export default loadUserMiddleware
