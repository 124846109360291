import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Img from 'react-image'
import { Button, Card, Checkbox, Menu, Dropdown } from 'antd'
import { truncate, getSourceByName } from 'utils/common.util'

const { Meta } = Card

class SharedFileCard extends Component {
  render() {
    const {
      t,
      id,
      key,
      _i,
      setFileLoading,
      setURL,
      genURL,
      setOpenFilePreviewModal,
      setSelectedItem,
      fileItem,
    } = this.props

    return (
      <div style={{ padding: 0, margin: 0, border: 0 }}>
        <Card
          bordered={false}
          hoverable
          style={{
            height: 170,
            width: 150,
            padding: '5px 0',
            marginBottom: '10px',
          }}
          bodyStyle={{ padding: '5px !important' }}
          onDoubleClick={async evt => {
            evt.stopPropagation()
            setFileLoading(true)
            setURL(await genURL(fileItem))
            setOpenFilePreviewModal(true)
            setSelectedItem(fileItem)
            setFileLoading(false)
          }}
          onClick={e => {
            if (e.currentTarget.classList.contains('card-selected')) {
              e.currentTarget.classList.remove('card-selected')
            } else {
              e.currentTarget.classList.add('card-selected')
            }
          }}
          cover={
            <div className="center-cropped">
              <Img
                src={[
                  `https://thumb.ac-data.info/${_i}/${fileItem.path}${fileItem.fileName}`,
                  `https://thumb.ac-data.info/f62ff766-1bb3-463f-b671-72f3974c07ACWORKS/${fileItem.path}${fileItem.fileName}`,
                ]}
                style={{ maxHeight: 100 }}
                unloader={
                  <img
                    src={getSourceByName(fileItem.fileName)}
                    alt=""
                    style={{ width: 'fit-content', marginTop: 20, userSelect: 'none' }}
                  />
                }
              />
            </div>
          }
        >
          <Meta description={truncate(fileItem.fileName, 15)} style={{ width: 150 }} />
        </Card>
      </div>
    )
  }
}

export default withTranslation()(SharedFileCard)
