import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const lang = localStorage.getItem('lang')
if (!lang) {
  localStorage.setItem('lang', 'ja')
}

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      language: 'EN',
      change_language: 'Language',
      my_page: 'My Page',
      my_folder: 'My Folders',
      trash_bin: 'Trash Bin',
      add_files: 'Add Files',
      add_folder: 'New Folder',
      folder_name: "Folder's Name",
      folder_name_placeholder: "Enter new folder's name",
      create_button: 'Create',
      create_folder_success: 'Folder is created successfully',
      create_folder_fail: 'Folder is created failed',
      transfer_tab: 'Transfer Files',
      stored_tab: 'Cloud Storage',
      login_button: 'Login',
      logout_button: 'Logout',
      signup_button: 'Sign Up',
      select_all_button: 'Select All',
      share: 'Share',
      notice_title: 'Notice',
      notice_content_1:
        'If you upload a file without logging in, your data will be deleted when your browser cache disappears or after 14 days.',
      notice_content_2: 'We recommend you to log in. ',
      notice_content_3: "If you don't have an account, please register for a free membership.",
      notice_button: 'Agree and Close',
      close_button: 'Close',
      login_notice_not_user:
        '※Currently, all data is deleted when the browser cache is cleared or after 14 days. Please sign up for a free account below.',
      login_notice_free_user_title: 'How to increase capacity',
      login_notice_free_user_content_1: 'If you become a premium member with ',
      login_notice_free_user_content_2: ', the storage amount will be increased to 30GB.',
      user_not_login: 'Non-Member',
      user_free: 'Free Member',
      user_premium: 'Premium Member',
      user_not_login_desc: '10GB cache storage capacity',
      user_free_desc: '10GB cloud storage capacity',
      user_premium_desc: '30GB cloud storage capacity',
      // DELETE FILE
      delete_folder: 'Delete All',
      delete_selected_files: 'Delete',
      delete_files_success: 'Files deleted successfully',
      delete_files_fail: 'Files delete failed',
      delete_folder_success: 'Files deleted successfully',
      delete_folder_fail: 'Files delete failed',
      deldete_file_confirmation: 'Do you want to delete these files?',
      deldete_folder_confirmation: 'Do you want to delete these files?',
      delete_content: 'When clicked the OK button, this dialog will be closed after 1 second',
      file_deleted: 'Files were deleted',
      delete_forever: 'Delete Forever',
      delete_forever_confirmation: 'Do you want to delete these files?',
      delete_forever_content:
        'These files will be delete immediately. You cannot undo this action.',
      restore_file: 'Restore',
      restore_file_confirmation: 'Do you want to restore these files and folders?',
      restore_file_content: 'These files and folders will be restored to their previous location.',
      restore_files_success: 'Restored successfully',
      restore_files_fail: 'Restored failed',
      restore_alert_title: 'This folder is in your trash',
      restore_alert_content: "To view this folder, you'll need to restore it from your trash.",
      duplicate_alert_content_1: 'The name “',
      duplicate_alert_content_2: '” is already taken. Please choose a different name.',
      mail_maga_alert_title: "FreebieAC's materials",
      mail_maga_alert_content: "You cannot share the FreebieAC's materials.",
      // UPLOAD FILE
      dropzone_title: 'Free File Transfer Service Without Registration',
      upload_button: 'Upload',
      upload_completing: 'Completing ...',
      stop_button: 'Cancel',
      zip_button: 'Zip',
      upload_files_title: 'Files',
      upload_folders_title: 'Folders',
      upload_click_or_drag: 'Drag and drop files',
      upload_support: 'You can upload multiple files at the same time',
      upload_from_url: 'Upload from URL',
      file_url: "File's URL",
      rename_file: 'Rename file to',
      upload_select_files: 'Upload',
      upload_files_success: 'Files uploaded successfully',
      upload_files_fail: 'Files upload failed',
      different_file_extension: 'Different file extension',
      invalid_url: 'Invalid URL',
      upload_over_storage: 'Free user can upload files up to 10GB',
      upload_over_storage_premium: 'Premium user can upload files up to 30GB',
      total_storage_pre_10: ' (',
      total_storage_sur_10: ' %)/10GB used',
      total_storage_pre_30: ' (',
      total_storage_sur_30: ' %)/30GB used',
      storage_title: 'Storage',
      upload_status: 'Status',
      // FILE LIST HEADER
      list_file_name: "File's Name",
      list_file_size: 'Size',
      list_last_modified: 'Last Modified',
      list_file_preview: 'Preview',
      list_copy_url: 'Share URL',
      list_download: 'Download',
      list_delete_file: 'Delete',
      list_file_expired: 'Expiry',
      button_copy: 'Copy',
      button_setup: 'Set',
      day1: '1 day',
      day3: '3 days',
      day7: '7 days',
      day10: '10 days',
      day30: '30 days',
      day65: '65 days',
      // DOWNLOAD FILE,
      file_size: "File's Size",
      file_created: 'Last Modified',
      file_downloaded_times: 'Downloaded times',
      file_downloaded: 'Files were downloaded',
      download_files_tittle: 'Download Files',
      file_download_password: 'Please input password to download',
      download_button: 'Download',
      downloading_button: 'Downloading',
      download_files_success: 'File is downloading...',
      download_files_fail: 'Files download failed',
      invalid_password: 'Your password is invalid',
      maximum_downloaded: 'You have reached the maximum number of downloads.',
      // SAVE FILE
      save_button: 'Save to Cloud',
      save_files_success: 'Files saved successfully',
      save_files_fail: 'Files save failed',
      // SHARE FILE
      share_folder: 'Share All',
      share_selected_files: 'Share',
      share_button: 'Share',
      share_files_success: 'Files shared successfully',
      share_files_fail: 'Files share failed',
      cancel_button: 'Cancel',
      shared_folder_password: 'Protected Sharing',
      shared_folder_enter: 'Enter',
      shared_folder_message: 'Message',
      shared_folder_title: 'Shared Folder',
      back_button: 'Back',
      rename_button: 'Rename',
      search_placeholder: 'Enter search text',
      // SHARE FORM
      sharing_form_tittle: 'Sharing',
      sharing_form_subject: 'Subject',
      sharing_form_subject_placeholder: "Enter email's subject",
      sharing_form_password: 'Password',
      sharing_form_password_placeholder: "Enter your file's password",
      sharing_form_sender: 'Sender',
      sharing_form_sender_required: "Sender's email is required",
      sharing_form_sender_placeholder: "Enter sender's email",
      sharing_form_receivers: 'Receivers',
      sharing_form_receivers_required: "Receivers' emails is required",
      sharing_form_receivers_placeholder: "Enter receivers' emails",
      sharing_form_notification: 'Notification',
      sharing_form_message: 'Message',
      sharing_form_message_required: 'Message is required',
      sharing_form_message_placeholder: 'Enter your message...',
      sharing_form_note: '* The download URL is automatically inserted in email.',
      sharing_form_verify_button: 'Verify',
      sharing_form_verify_code: 'Code',
      sharing_form_verify_code_placeholder: 'Enter verification code',
      sharing_form_verify_success: 'Email is verified',
      sharing_form_verify_fail: 'Cannot verify the email',
      sharing_password_set_success: "File's password is set successfully",
      sharing_password_set_fail: "File's password is set failed",
      sharing_form_no_emails_suggestion: 'No email suggestions',
      sharing_form_add_new_email: 'Add',
      // MAIN PAGE
      my_files: 'My Files',
      our_company: 'Company',
      guide_blog: 'Guide',
      faqs: 'FAQs',
      personal_policy: 'Personal Policy',
      terms: 'Terms',
      policy: 'Privacy Policy',
      copyright: 'Copyright © ACworks Co.,Ltd. All rights reserved.',
      feedback: 'Feedback',
      feedback_title: 'Help us improve the site',
      feedback_placeholder:
        'Tell us what you think. Please note that individual replies cannot be made.',
      feedback_button: 'Feedback',
      copy_file_url_success: 'Shared URL is copied',
      copy_file_url_fail: 'Cannot get shared URL',
      button_yes: 'Yes',
      button_no: 'No',
      no_data: 'No Data',
      ac_data: 'AC Data',
      ac_data_slogan: 'AC Data is a set of modern professional data sharing',
      ac_works: 'AC works',
      ac_works_address:
        'Tosabori Prime Building 5th Floor, 1-5-11 Tosabori, Nishi-ku, Osaka-shi, 550-0001 JAPAN',
      // TEMPLATE PAGE
      section_above_title: 'With ACdata, you can safely transfer files.',
      section_above_content_1:
        'We always take security measures such as SSL communication, Web Application Firewall, virus check.',
      section_above_content_2:
        'The stored data will be strictly controlled with access restrictions, and will be completely deleted automatically after the storage period.',
      section_above_content_3:
        'Except for special circumstances such as violations of laws and regulations, the administrator will never view the data you have received.',
      section_above_content_4:
        'Please be assured that not only your e-mail address, but also your messages will be encrypted and your privacy will be considered as much as possible.',
      section_below_1_title: 'We support telework! ',
      section_below_1_title_1: 'Supports unlimited capacity (250GB/file).',
      section_below_1_content_1:
        'The spread of the new coronavirus (COVID-19) has encouraged many businesses to recommend home-based work and increased file transfer opportunities.',
      section_below_1_content_2:
        'ACdata supports telework with unlimited file space (250GB/file), up to 65 days of file storage, and completely free.',
      section_below_1_content_3: '',
      section_below_2_title:
        'Free 10GB! Cloud storage feature now allows you to store files for a long time.',
      section_below_2_content_1:
        "Now you can store your files for a long time with free online storage. You can use the cache of your browser without registering as a member, but in that case, the cache will be deleted or the file will be deleted 14 days after uploading the file. If you <a href='https://accounts.ac-illust.com/signup?lang=en&serviceURL=https://ac-data.info'>register</a> as a free member, you will be able to log in and leave your files with confidence.",
      section_below_2_content_2:
        'If you become a premium member of either Illustration AC, Photo AC or Silhouette AC, your store will be upgraded to 30GB cloud storage capacity .',
      bookmark_if: 'If you think photoAC is best! Press',
      bookmark_then: 'and mark it!',
      illustAC: 'illustAC',
      photoAC: 'photoAC',
      silhouetteAC: 'silhouetteAC',
      freebieAC: 'Freebie AC',
      newyearAC: 'newyearAC',
      comma_symbol: ', ',
      grid_view: 'Grid',
      table_view: 'Table',
      grid_view_sort_desc: 'Click to sort by ascend',
      grid_view_sort_asc: 'Click to sort by ascend',
      grid_view_cancel_sort: 'Click to cancel sort',
      logo_description:
        'A free large-capacity file transfer service that anyone can easily use without user registration.',
      select_files: 'Select files',
      text_survey: 'Survey',
    },
  },
  ja: {
    translation: {
      language: 'JP',
      change_language: '言語変更',
      my_page: 'マイページ',
      my_folder: 'マイフォルダ',
      trash_bin: 'ごみ箱',
      add_files: 'ファイル追加',
      add_folder: '新規フォルダ',
      folder_name: 'フォルダー名',
      folder_name_placeholder: '新しいフォルダの名前を入力してください',
      create_button: '作成',
      create_folder_success: 'フォルダーが正常に作成されました。',
      create_folder_fail: 'フォルダーの作成に失敗しました。',
      transfer_tab: 'ファイル転送',
      stored_tab: 'クラウドストレージ',
      login_button: 'ログイン',
      logout_button: 'ログアウト',
      signup_button: '無料登録',
      select_all_button: 'すべて選択',
      share: '共有',
      notice_title: 'お知らせ',
      notice_content_1:
        '会員登録しない場合、クラウド機能のファイルは、キャッシュが削除されるか、または14日後に削除されます。',
      notice_content_2: 'ログインすることをおすすめします。',
      notice_content_3: 'アカウントがない場合は、無料会員登録をお願いします。',
      notice_button: '同意して閉じる',
      close_button: '閉じる',
      login_notice_not_user:
        '※会員登録しない場合、クラウド機能のファイルは、キャッシュが削除されるか、または14日後に削除されます。会員登録後ログインすると長期保存が可能です。',
      login_notice_free_user_title: '容量の増やし方',
      login_notice_free_user_content_1: '',
      login_notice_free_user_content_2: 'のいずれかでプレミアム会員になると、30GBに増量されます。',
      user_not_login: '非会員',
      user_free: '無料会員',
      user_premium: 'プレミアム会員',
      user_not_login_desc: '10GBキャッシュ保存容量',
      user_free_desc: '10GBクラウド保存容量',
      user_premium_desc: '30GBクラウド保存容量',
      // DELETE FILE
      delete_folder: '全削除',
      delete_selected_files: '削除',
      delete_files_success: 'ファイルは正常に削除されました。',
      delete_files_fail: 'ファイルの削除に失敗しました。',
      delete_folder_success: 'ファイルは正常に削除されました。',
      delete_folder_fail: 'ファイルの削除に失敗しました。',
      deldete_file_confirmation: '削除しますか？',
      deldete_folder_confirmation: '削除しますか？',
      delete_content: '「OK」ボタンをクリックすると、このダイアログは1秒後に閉じられます。',
      file_deleted: 'ファイルが削除されました。',
      delete_forever: '完全に削除',
      delete_forever_confirmation: '削除しますか？',
      delete_forever_content:
        'これらのファイルはすぐに削除されます。 このアクションを元に戻すことはできません。',
      restore_file: '復元',
      restore_file_confirmation: '復元しますか？',
      restore_file_content: '以前の場所に復元されます。',
      restore_files_success: '復元されました。',
      restore_files_fail: '復元に失敗しました。',
      restore_alert_title: 'このフォルダはゴミ箱にあります',
      restore_alert_content: 'このフォルダを表示するには、ゴミ箱から復元する必要があります。',
      duplicate_alert_content_1: '「',
      duplicate_alert_content_2:
        '」という名前はすでに使われています。 別の名前を選択してください。',
      mail_maga_alert_title: 'FreebieACの素材',
      mail_maga_alert_content: 'FreebieACの素材を共有することはできません。',
      // UPLOAD FILE
      dropzone_title: '無料ファイル転送サービス',
      upload_button: 'アップロード',
      upload_completing: '完了しています...',
      zip_button: 'まとめる',
      stop_button: 'アップロード中止',
      upload_files_title: 'ファイル',
      upload_folders_title: 'フォルダ',
      upload_click_or_drag: 'ファイルをドラッグ&ドロップ',
      upload_support: '複数ファイル10ギガまでを同時にアップロードできます。',
      upload_from_url: 'URLからアップロード',
      file_url: 'ファイルのURL',
      rename_file: 'ファイルの名前を変更',
      upload_select_files: 'ファイル選択',
      upload_files_success: 'ファイルがアップロードされました。',
      upload_files_fail: 'ファイルのアップロードに失敗しました。',
      different_file_extension: '異なるファイル拡張子。',
      invalid_url: '無効なURL',
      upload_over_storage: '無料ユーザーは最大10ギガのファイルをアップロードできます。',
      upload_over_storage_premium:
        'プレミアムユーザーは最大30ギガのファイルをアップロードできます。',
      total_storage_pre_10: '(',
      total_storage_pre_30: '(',
      total_storage_sur_10: '%)/10GB使用中',
      total_storage_sur_30: '%)/30GB使用中',
      storage_title: 'ストレージ',
      upload_status: '状態',
      // FILE LIST HEADER
      list_file_name: 'ファイル名',
      list_file_size: 'サイズ',
      list_last_modified: '最終更新日',
      list_file_preview: 'プレビュー',
      list_copy_url: '共有URL',
      list_download: 'ダウンロード',
      list_delete_file: '削除',
      list_file_expired: '保存期限',
      button_copy: 'コピー',
      button_setup: '設定',
      day1: '1日',
      day3: '3日',
      day7: '7日',
      day10: '10日',
      day30: '30日',
      day65: '65日',
      // DOWNLOAD FILE,
      file_size: 'ファイルサイズ',
      file_created: '最終更新日',
      file_downloaded_times: 'ダウンロード回数',
      file_downloaded: 'ファイルがダウンロードされました。',
      file_download_password: 'ダウンロードするにはパスワードを入力してください。',
      download_files_tittle: 'ファイルダウンロード',
      download_button: 'ダウンロード',
      downloading_button: 'ダウンロード',
      download_files_success: 'ファイルをダウンロードしています...',
      download_files_fail: 'ファイルのダウンロードに失敗しました。',
      invalid_password: 'パスワードが無効です。',
      maximum_downloaded: 'ダウンロードの最大数に達しました。',
      // SAVE FILE
      save_button: 'クラウドに保存',
      save_files_success: 'ファイルはクラウドに保存されました。',
      save_files_fail: 'ファイルの保存に失敗しました。',
      share_button: 'メールで送信',
      rename_button: '名前変更',
      search_placeholder: '検索テキストを入力',
      // SHARE FILE
      share_files_success: 'ファイルが正常に共有されました。',
      share_files_fail: 'ファイル共有に失敗しました。',
      share_folder: '全共有',
      share_selected_files: '共有',
      cancel_button: 'キャンセル',
      shared_folder_password: '保護された共有',
      shared_folder_enter: '入る',
      shared_folder_message: 'メッセージ',
      shared_folder_title: '共有したフォルダ',
      back_button: '戻る',
      // SHARE FORM
      sharing_form_tittle: 'メールで送信',
      sharing_form_subject: '件名',
      sharing_form_subject_placeholder: '件名を入力してください',
      sharing_form_password: 'パスワード',
      sharing_form_password_placeholder: 'ファイルのパスワードを入力してください',
      sharing_form_sender: '送信者',
      sharing_form_sender_required: '送信者のメールアドレスが必要です。',
      sharing_form_sender_placeholder: '送信者のメールアドレスを入力してください',
      sharing_form_receivers: '受信者',
      sharing_form_receivers_required: '受信者のメールが必要です。',
      sharing_form_receivers_placeholder: '受信者のメールアドレスを入力してください',
      sharing_form_notification: '受取確認',
      sharing_form_message: 'メッセージ',
      sharing_form_message_required: 'メッセージが必要です。',
      sharing_form_message_placeholder: 'メッセージを入力してください...',
      sharing_form_note: '※ ダウンロードURLはメッセージ末尾に自動挿入されます。',
      sharing_form_verify_button: '確認',
      sharing_form_verify_code: '確認コード',
      sharing_form_verify_code_placeholder: '認証コードを入力してください',
      sharing_form_verify_success: 'メールが確認されました。',
      sharing_form_verify_fail: 'メールを確認できません。',
      sharing_password_set_success: 'ファイルのパスワードが正常に設定されました。',
      sharing_password_set_fail: 'ファイルのパスワードの設定に失敗しました。',
      sharing_form_no_emails_suggestion: 'メールの提案はありません',
      sharing_form_add_new_email: '追加',
      // MAIN PAGE
      my_files: 'マイファイル',
      our_company: '運営会社',
      guide_blog: '操作方法',
      faqs: 'よくある質問',
      personal_policy: '特定個人情報基本方針',
      terms: '利用規約',
      policy: 'プライバシーポリシー',
      copyright: 'Copyright © ACワークス株式会社 All rights reserved.',
      feedback: 'ACヘルプセンター',
      feedback_title: 'サイト改善にご協力ください',
      feedback_placeholder:
        'あなたのご意見をお聞かせください。個別返信はできかねますので予めご了承をお願い致します。',
      feedback_button: '気づいたことを知らせる',
      copy_file_url_success: '共有URLがコピーされました。',
      copy_file_url_fail: '共有URLがコピーできません。',
      button_yes: 'はい',
      button_no: 'いいえ',
      no_data: 'データなし',
      ac_data: 'ACデータ',
      ac_data_slogan: 'ACデータは現代のプロフェッショナルデータ共有のセットです。',
      ac_works: 'ACワークス株式会社',
      ac_works_address: 'ACワークス株式会社　〒550-0001 大阪市西区土佐堀1丁目5-11',
      // TEMPLATE PAGE
      section_above_title: '安心して安全にファイル転送ができます。',
      section_above_content_1:
        '常時SSL通信、Web Application Firewall、ウィルスチェックなど安全対策を行っています。',
      section_above_content_2:
        'お預かりしたデータは、アクセス制限を設け厳重に管理し、保存期限を過ぎると自動的に完全消去します。',
      section_above_content_3:
        '法令違反等の特殊な事情を除き、お預かりしたデータを管理者が確認することは一切ありません。',
      section_above_content_4:
        'メールアドレスはもちろん、メッセージも暗号化し、ご利用者様のプライバシーに最大限配慮していますのでご安心ください。',
      section_below_1_title: 'テレワークを応援します！',
      section_below_1_title_1: '容量無制限（1ファイル250ギガ）に対応。',
      section_below_1_content_1:
        '新型コロナウイルス（COVID-19）の蔓延により、多くの企業が在宅ワークを推奨し、ファイル転送の機会が増加しています。',
      section_below_1_content_2:
        'ACdataでは、ファイル容量を無制限（1ファイル 250ギガ）、ファイル保存期限最大65日、完全無料でテレワークを応援します。',
      section_below_1_content_3: '',
      section_below_2_title:
        '無料で10GB！クラウドストレージ機能で長期間ファイル保存が可能になりました。',
      section_below_2_content_1:
        "無料のオンラインストレージで長期間ファイルの保存ができるようになりました。会員登録をせずにブラウザのキャッシュを使いご利用いただくこともできますが、その場合、キャッシュが削除されるか、もしくはファイルをアップロードしてから14日後にファイルが削除されます。無料の<a href='https://accounts.ac-illust.com/signup?lang=jp&serviceURL=https://ac-data.info'>会員登録</a>をすればログインが可能となり、ファイルを安心してお預けいただけます。",
      section_below_2_content_2:
        'イラストAC、写真AC、シルエットACのいずれかでプレミアム会員になれば、30GBまでクラウドストレージの容量がアップグレードされます。',
      bookmark_if: 'ACdataを気に入った場合は、',
      bookmark_then: 'を押してブックマークに追加してください。',
      illustAC: 'イラストAC',
      photoAC: '写真AC',
      silhouetteAC: 'シルエットAC',
      freebieAC: 'Freebie AC',
      newyearAC: '年賀状AC',
      comma_symbol: '、',
      grid_view: 'グリッド',
      table_view: 'リスト',
      grid_view_sort_desc: '降順で並べ替え',
      grid_view_sort_asc: '昇順で並べ替え',
      grid_view_cancel_sort: '並べ替えをキャンセル',
      logo_description:
        'ACデータは誰でも簡単に使える登録不要の無料大容量ファイル転送サービスです。',
      select_files: 'ファイルを選択',
      text_survey: 'アンケート',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang'),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
