import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import DownloadFile from 'pages/download/DownloadFile'
import SharedFolder from 'pages/share/SharedFolder'
import MailMagazine from 'pages/mailMagazine/MailMagazine'
import { createShortCut } from './services/upload'
import i18n from './i18n'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // files
  {
    path: '/',
    Component: loadable(() => import('pages/dashboard/files')),
    exact: true,
  },
  // {
  //   path: '/dashboard/terms',
  //   Component: loadable(() => import('pages/dashboard/terms')),
  //   exact: true,
  // },
  // {
  //   path: '/dashboard/policy',
  //   Component: loadable(() => import('pages/dashboard/policy')),
  //   exact: true,
  // },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/d/:metadata" render={props => <DownloadFile {...props} />} />
            <Route exact path="/s/:metadata" render={props => <SharedFolder {...props} />} />
            <Route exact path="/download/:metadata" render={props => <DownloadFile {...props} />} />
            <Route exact path="/m/:metadata" render={props => <MailMagazine {...props} />} />
            <Route exact path="/dashboard/files" render={() => <Redirect to="/" />} />
            <Route exact path="/folders" render={() => <Redirect to="/" />} />
            <Route exact path="/folder" render={() => <Redirect to="/" />} />
            <Route
              exact
              path="/en"
              render={() => {
                i18n.changeLanguage('en')
                localStorage.setItem('lang', 'en')
                return <Redirect to="/" />
              }}
            />
            <Route
              exact
              path="/cloud"
              render={() => {
                localStorage.setItem('cloud', '1')
                localStorage.setItem('tabIndex', '2')
                return <Redirect to="/" />
              }}
            />
            <Route
              exact
              path="/transfer"
              render={() => {
                localStorage.setItem('tabIndex', '1')
                return <Redirect to="/" />
              }}
            />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component history={history} />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
