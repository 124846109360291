import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Card, Checkbox } from 'antd'
import { truncate, getSourceByName } from 'utils/common.util'

const { Meta } = Card

class SharedFolderCard extends Component {
  render() {
    const { id, key, loadSharedFolders, folderItem } = this.props

    return (
      <div>
        <Card
          bordered={false}
          style={{
            cursor: 'pointer',
            height: 170,
            width: 150,
            padding: '5px 0',
            marginBottom: '10px',
            zIndex: 1000,
          }}
          bodyStyle={{ padding: '5px !important' }}
          onDoubleClick={() => loadSharedFolders(false, folderItem.path)}
          onClick={e => {
            if (e.currentTarget.classList.contains('card-selected')) {
              e.currentTarget.classList.remove('card-selected')
            } else {
              e.currentTarget.classList.add('card-selected')
            }
          }}
          cover={
            <img
              src={getSourceByName('folder')}
              alt=""
              style={{ width: 'fit-content', marginTop: 59, userSelect: 'none' }}
            />
          }
        >
          <Meta description={truncate(folderItem.name, 15)} style={{ width: 150 }} />
        </Card>
      </div>
    )
  }
}

export default withTranslation()(SharedFolderCard)
