import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal, Button, Input } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import { feedback } from 'services/upload'

const { TextArea } = Input

class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = { captchaRes: '', disableButton: true, message: '', success: '' }
  }

  render() {
    const { t } = this.props
    const { isVisible, toggle } = this.props
    const { captchaRes, disableButton, message, success } = this.state

    const onChange = value => {
      this.setState({ captchaRes: value })
      if (message !== '') {
        this.setState({ disableButton: false })
      }
    }

    const handleChangeMassage = e => {
      this.setState({ message: e.target.value })
      if (captchaRes !== '') {
        this.setState({ disableButton: false })
      }
    }
    return (
      <Modal
        {...this.props}
        title={t('feedback_title')}
        style={{ top: 25 }}
        visible={isVisible || false}
        footer={null}
        onOk={() => toggle(false)}
        onCancel={() => toggle(false)}
        destroyOnClose
      >
        {success === '' && (
          <div id="feedbackForm">
            <div className="form-group rown-message">
              <TextArea
                className="form-control"
                rows={5}
                placeholder={t('feedback_placeholder')}
                required
                value={message}
                onChange={handleChangeMassage}
              />
            </div>
            <ReCAPTCHA sitekey="6LeUZSUTAAAAAO0lPviBu3JbAQycXHAyaitMq9iW" onChange={onChange} />
            <br />
            <center>
              <Button
                style={{
                  fontSize: '16px',
                  verticalAlign: 'middle',
                  width: '202px',
                  height: '36px',
                  color: '#fff',
                  backgroundColor: '#6c757d',
                  borderColor: '#6c757d',
                }}
                type="primary"
                className="ant-btn"
                disabled={disableButton}
                onClick={async () => {
                  const res = await feedback(message, captchaRes)
                  this.setState({ success: res })
                }}
              >
                {t('feedback_button')}
              </Button>
            </center>
          </div>
        )}

        {success === 'success' && (
          <center>
            <span style={{ color: 'green' }}>メッセージを送信しました。</span>
          </center>
        )}

        {success === 'fail' && (
          <center>
            <span style={{ color: 'red' }}>
              エラーが発生しました。お手数ですが、時間をおいて再度送信してください。
            </span>
          </center>
        )}
      </Modal>
    )
  }
}

export default withTranslation()(Feedback)
