/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import {
  createShortCut,
  checkDuplicateFolder,
  createFolderExistedUser,
  createFolderNewUser,
  updateUserSSOId,
  getUserBySSOId,
} from 'services/upload'

const MailMagazine = ({ match: { params }, t }) => {
  const { metadata } = params
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetch() {
      if (sessionStorage.getItem('sessionUserId') !== null) {
        if (localStorage.getItem('uuid') === null || localStorage.getItem('uuid') === '') {
          await createFolderNewUser({
            path: 'メルマガ素材/',
          }).then(async res => {
            localStorage.setItem('uuid', res.id)
            if (sessionStorage.getItem('sessionUserId') !== null) {
              localStorage.setItem('uuid', res.id)
              await updateUserSSOId({ sso: sessionStorage.getItem('sessionUserId') })
            }
          })
        }
        const { uuid } = await getUserBySSOId({ sso: sessionStorage.getItem('sessionUserId') })
        setLoading(true)
        await createShortCut(
          {
            path: 'メルマガ素材/',
            fileName: metadata,
            origin: 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS',
          },
          uuid,
        )
        localStorage.setItem('tabIndex', '2')
        setLoading(false)
      } else {
        console.log('loginnnnnnnnnn', window.location.search)
        if (window.location.search && window.location.search.includes('ssoToken')) return true
        const link = document.createElement('a')
        link.href = `https://accounts.freebieac.com/login?serviceURL=https://ac-data.info/m/${metadata}`
        document.body.appendChild(link)
        return link.click()
      }
      return true
    }
    fetch()
  }, [])

  return (
    <>
      {loading && (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 34, color: '#108ee9' }} spin />} />
      )}
      {!loading && <Redirect to="/" />}
    </>
  )
}

export default withTranslation()(MailMagazine)
