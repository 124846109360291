/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, List, Empty } from 'antd'
import { formatDistance, addHours, parse } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'
import { format } from 'date-fns/esm'
import _path from 'path'
import { getSharedDownloadURL, downloadFile, updateDownloadTime } from 'services/upload'
import { openNotificationWithIcon } from 'utils/common.util'
import SharedFileCard from './SharedFileCard'
import SharedFolderCard from './SharedFolderCard'

const SharedList = ({
  t,
  shared,
  dataSource,
  loadSharedFolders,
  title,
  sender,
  message,
  expired,
  setURL,
  genURL,
  setSelectedItem,
  setOpenFilePreviewModal,
  fileLoading,
  setFileLoading,
  _i,
}) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      })
    }
    window.addEventListener('resize', handleResize)
  }, [])

  let expiryTime = new Date()
  if (expired) expiryTime = parse(expired, 'yyyy-MM-dd HH:mm:ss', new Date())
  return (
    <List
      bordered
      header={
        <div>
          <span style={{ fontSize: 20 }}>
            <strong>{title === '' ? t('shared_folder_title') : title}</strong>
          </span>
          <div style={{ float: 'right', textAlign: 'center' }}>
            <Button
              // style={{ float: 'right' }}
              type="primary"
              onClick={async evt => {
                evt.stopPropagation()
                setFileLoading(true)
                const data = {
                  url: await getSharedDownloadURL({ shared }),
                  name: shared,
                }
                await downloadFile(data).then(res => {
                  updateDownloadTime({ shared, type: 'folder' })
                })
                setFileLoading(false)
              }}
            >
              <DownloadOutlined />
              <span>{t('list_download')}</span>
            </Button>
            <br />
            <span style={{ fontSize: 12 }}>
              <i>
                {t('list_file_expired')}
                {': '}
                {format(expiryTime, 'PPP', {
                  locale: localStorage.getItem('lang') === 'en' ? enUS : ja,
                })}
              </i>
            </span>
          </div>
          <br />
          <span style={{ fontSize: 13 }}>
            <i>{sender === 'aaa@aaa.aaa' ? '' : sender}</i>
          </span>
          <br />
          {message !== '' && (
            <>
              <br />
              <strong>{t('shared_folder_message')}</strong>
              <br />
              {message}
            </>
          )}
        </div>
      }
      style={{ minHeight: '60vh', paddingTop: 10, marginBottom: 50, paddingRight: 15 }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={t('no_data')} />,
      }}
      grid={{ gutter: 5, column: Math.floor(dimensions.width / 350) }}
      dataSource={dataSource}
      pagination={{
        pageSize: 18,
        simple: true,
        hideOnSinglePage: true,
      }}
      loading={
        fileLoading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false
      }
      renderItem={item => {
        return (
          <List.Item
            style={{
              textAlign: '-webkit-center',
              marginBottom: 0,
              width: 'fit-content',
              marginTop: 10,
            }}
          >
            {item.type === 'file' && (
              <SharedFileCard
                t={t}
                id={item.id}
                key={item.key}
                setFileLoading={setFileLoading}
                setURL={setURL}
                genURL={genURL}
                setOpenFilePreviewModal={setOpenFilePreviewModal}
                setSelectedItem={setSelectedItem}
                fileItem={item}
                _i={_i}
              />
            )}
            {item.type === 'folder' && (
              <SharedFolderCard
                t={t}
                id={item.id}
                key={item.key}
                loadSharedFolders={loadSharedFolders}
                folderItem={item}
              />
            )}
          </List.Item>
        )
      }}
    />
  )
}

export default withTranslation()(SharedList)
