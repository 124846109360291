const jwt = require('jsonwebtoken')
const { publicKey } = require('./ssoConfig').keys

const ISSUER = 'ac-sso'
// console.log('inside jwTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTt')
const verifyJwtToken = token =>
  new Promise((resolve, reject) => {
    jwt.verify(token, publicKey, { issuer: ISSUER, algorithms: ['RS256'] }, (err, decoded) => {
      if (err) return reject(err)
      return resolve(decoded)
    })
  })
module.exports = Object.assign({}, { verifyJwtToken })
