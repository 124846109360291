import { useTranslation } from 'react-i18next'
import { all, put, call } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import i18n from '../../i18n'

export function* GET_DATA() {
  // console.log('i18nnnnnn', i18n.t)
  const menuData = yield call(getMenuData)
  const menu = menuData.map(item => {
    return {
      title: item.title,
      // title: i18n.t(item.title),
      key: item.key,
      icon: item.icon,
      count: item.count,
      url: item.url,
    }
  })

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData: menu,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
