import { REFRESH_FILES, REFRESH_FILES_SUCCESS } from './actions'

export const refreshFiles = flag => {
  return {
    type: REFRESH_FILES,
    payload: flag,
  }
}

export const refreshFilesSuccess = result => {
  return {
    type: REFRESH_FILES_SUCCESS,
    payload: result,
  }
}
