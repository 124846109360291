import React from 'react'
import { withTranslation } from 'react-i18next'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'
import i18n from '../../../../i18n'

class LanguageSwitcher extends React.Component {
  render() {
    const { t, isLoggedIn, ...other } = this.props
    const changeLanguage = lng => {
      i18n.changeLanguage(lng)
      document.documentElement.lang = lng
      localStorage.setItem('lang', lng)
    }
    const menu = (
      <Menu selectable={false}>
        <Menu.Item onClick={() => changeLanguage('ja')}>
          <span className="text-uppercase font-size-12 mr-2">JP</span>
          日本語
        </Menu.Item>
        <Menu.Item onClick={() => changeLanguage('en')}>
          <span className="text-uppercase font-size-12 mr-2">EN</span>
          English
        </Menu.Item>
      </Menu>
    )
    return (
      <>
        {!isLoggedIn && (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <div className={styles.dropdown}>
              <span>{t('language')}</span>
            </div>
          </Dropdown>
        )}
        {isLoggedIn && (
          <Menu.SubMenu
            title={t('change_language')}
            {...other}
            style={{ paddingLeft: 0, justifyContent: 'center' }}
          >
            <Menu.Item onClick={() => changeLanguage('ja')}>
              <span className="text-uppercase font-size-12 mr-2">JP</span>
              日本語
            </Menu.Item>
            <Menu.Item onClick={() => changeLanguage('en')}>
              <span className="text-uppercase font-size-12 mr-2">EN</span>
              English
            </Menu.Item>
          </Menu.SubMenu>
        )}
      </>
    )
  }
}

export default withTranslation()(LanguageSwitcher)
