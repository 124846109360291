import { REFRESH_FILES, REFRESH_FILES_SUCCESS, REFRESH_FILES_ERROR } from './actions'

const initialState = {
  refreshFiles: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_FILES:
      return { ...state, refreshFiles: action.payload }
    default:
      return state
  }
}
