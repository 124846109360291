import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
// import Loader from 'components/layout/Loader'
import PublicLayout from './Public'
import DownloadLayout from './Download'
import ShareLayout from './Share'
import AppLayout from './App'

const Layouts = {
  public: PublicLayout,
  download: DownloadLayout,
  share: ShareLayout,
  app: AppLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class Layout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      // user,
    } = this.props

    // // NProgress Management
    // const currentPath = pathname + search
    // if (currentPath !== this.previousPath) {
    // NProgress.start()
    // }

    // setTimeout(() => {
    // NProgress.done()
    // this.previousPath = currentPath
    // }, 10)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'app'
      }
      if (/^\/download(?=\/|$)/i.test(pathname) || /^\/d(?=\/|$)/i.test(pathname)) {
        return 'download'
      }
      if (/^\/s(?=\/|$)/i.test(pathname)) {
        return 'share'
      }
      return 'app'
    }

    const Container = Layouts[getLayout()]
    // const isUserAuthorized = user.authorized
    // const isUserLoading = user.loading
    // const isAuthLayout = getLayout() === 'auth'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      // if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      // return <Loader />
      // }
      // redirect to login page if current is not login page and user not authorized
      // if (!isAuthLayout && !isUserAuthorized) {
      // return <Redirect to="/system/login" />
      // }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="無料ファイル転送ならACデータ" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default withTranslation()(Layout)
