import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/layout/TopBar'
import AcProduct from 'components/layout/AcProduct'
import TemplateSectionBelow from 'components/layout/TemplateSectionBelow'
import TemplateSectionAbove from 'components/layout/TemplateSectionAbove'
import Footer from 'components/layout/Footer'
import { useTranslation, withTranslation } from 'react-i18next'
import { BellFilled, CloseOutlined } from '@ant-design/icons'

const mapStateToProps = ({ settings }) => ({ settings })
const formatDate = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
const getCookie = name => {
  const cookieArr = document.cookie.split(';')
  for (let cookie of cookieArr) {
    const [cookieName, cookieValue] = cookie.trim().split('=')
    if (cookieName === name) {
      return decodeURIComponent(cookieValue)
    }
  }
  return null // Trả về null nếu không tìm thấy
}
@withRouter
@connect(mapStateToProps)
class AppLayout extends React.PureComponent {
  state = {
    isHidden: false,
  }

  componentDidMount = () => {
    const date = localStorage.getItem('dateHideNoti')
    const today = new Date()
    if (date === formatDate(today) + '-' + getCookie('sessionUserId')) {
      this.setState({ isHidden: true })
    }
  }

  render() {
    const {
      children,
      settings: {
        isContentNoMaxWidth,
        isAppMaxWidth,
        isGrayBackground,
        isSquaredBorders,
        isCardShadow,
        isBorderless,
        isTopbarFixed,
        isGrayTopbar,
        selectedTab,
      },
      i18n,
    } = this.props
    const { isHidden } = this.state
    const isCloud = selectedTab === 'cloud'
    localStorage.setItem('sharedids', '[]')
    localStorage.setItem('login', '0')

    const handleHide = () => {
      this.setState({ isHidden: true })
      const today = new Date()
      localStorage.setItem('dateHideNoti', formatDate(today) + '-' + getCookie('sessionUserId'))
    }

    return (
      <Layout
        className={classNames({
          air__layout__contentNoMaxWidth: isContentNoMaxWidth,
          air__layout__appMaxWidth: isAppMaxWidth,
          air__layout__grayBackground: isGrayBackground,
          air__layout__squaredBorders: isSquaredBorders,
          air__layout__cardsShadow: isCardShadow,
          air__layout__borderless: isBorderless,
        })}
      >
        <AcProduct lang={i18n.language} />

        {/* <MenuLeft /> */}
        <Layout>
          <Layout.Header
            className={classNames('air__layout__header', {
              air__layout__fixedHeader: isTopbarFixed,
              air__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          {/* <div
            style={{
              backgroundColor: '#D1ECF1',
              borderLeft: '#17A2B8 3px solid',
              left: '40%',
              transform: 'translateX(-30%)',
              position: 'fixed',
              top: 0,
              color: '#0C5460',
              padding: '9px 0px',
              borderRadius: '4px',
              display: isHidden ? 'none' : 'inline-block',
              zIndex: '999999999',
            }}
          >
            <p
              style={{
                marginBottom: '0px',
                size: '13px',
              }}
            >
              <BellFilled
                style={{
                  margin: '0px 20px',
                  color: '#17A2B8',
                }}
              />
              【メンテナンスのお知らせ】2024年11月03日　午後22時から、メンテナンスのためACデータサイトは一時停止いたします。
              <a
                style={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  color: '#0C5460',
                }}
                href="https://acworks.co.jp/maintenance_acdata_20241103/"
                target="_blank"
              >
                詳細はこちら
              </a>
              <CloseOutlined
                onClick={handleHide}
                style={{
                  width: '14px',
                  height: '14px',
                  margin: '0px 20px',
                  cursor: 'pointer',
                }}
              />
            </p>
          </div> */}
          <div
            id="div-gpt-ad-1604031951387-0"
            style={{
              textAlign: 'center',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <script
              dangerouslySetInnerHTML={{
                __html: `
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1604031951387-0'); });
              `,
              }}
            />
          </div>
          <Layout.Content style={{ minHeight: '500px', position: 'relative', width: '100%' }}>
            <div
              style={{
                borderBottom: '2px solid #1b55e3',
                marginTop: 49,
                width: '100%',
                position: 'absolute',
              }}
            >
              {' '}
            </div>
            <div
              className="container-fluid py-3 mr-auto ml-auto"
              style={{ padding: '0', width: '75vw', maxWidth: '1400px' }}
            >
              {children}
            </div>
          </Layout.Content>
          {!isCloud && (
            <>
              <TemplateSectionAbove />
              <TemplateSectionBelow />
            </>
          )}

          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    )
  }
}

export default withTranslation()(AppLayout)
