/* eslint-disable react/jsx-indent */
import React, { useEffect, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Empty } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import FileViewer from 'react-file-viewer'
import _path from 'path'
import CustomModal from './CustomModal'
import { useHandleScroll } from '../../../hooks'

const FilePreviewModal = ({
  isVisible,
  toggle,
  selectedFile,
  URL,
  genURL,
  setSelectedItem,
  setURL,
  dataSource,
}) => {
  const allFilesInFolder = dataSource.filter(item => item.type === 'file')
  const numberOfFile = allFilesInFolder.length
  const [blockScroll, allowScroll] = useHandleScroll()

  useEffect(() => {
    blockScroll()
  }, [])

  const onClose = () => {
    allowScroll()
    toggle(false)
  }

  const handleNext = async currentId => {
    if (currentId === numberOfFile) currentId = 0
    const nextItem = allFilesInFolder.find(item => item.id === currentId + 1)
    setURL(await genURL(nextItem))
    setSelectedItem(nextItem)
  }

  const handlePrev = async currentId => {
    if (currentId === 1) currentId = numberOfFile + 1
    const prevItem = allFilesInFolder.find(item => item.id === currentId - 1)
    setURL(await genURL(prevItem))
    setSelectedItem(prevItem)
  }

  const keyDownHandle = useCallback(
    async event => {
      if (event.key === 'ArrowLeft' && selectedFile.id !== undefined) {
        let currentId = selectedFile.id
        if (currentId === 1) currentId = numberOfFile + 1
        const prevItem = allFilesInFolder.find(item => item.id === currentId - 1)
        setURL(await genURL(prevItem))
        setSelectedItem(prevItem)
      } else if (event.key === 'ArrowRight' && selectedFile.id !== undefined) {
        let currentId = selectedFile.id
        if (currentId === numberOfFile) currentId = 0
        const nextItem = allFilesInFolder.find(item => item.id === currentId + 1)
        setURL(await genURL(nextItem))
        setSelectedItem(nextItem)
      }
    },
    [selectedFile],
  )

  useEffect(() => {
    if (isVisible) document.addEventListener('keydown', keyDownHandle)
    return () => {
      if (isVisible) document.removeEventListener('keydown', keyDownHandle)
    }
  }, [selectedFile])
  const fileType = _path.extname(selectedFile.fileName).slice(1)

  return (
    <>
      {isVisible && (
        <CustomModal
          data={selectedFile}
          URL={URL}
          onCancel={onClose}
          footer={
            numberOfFile === 1
              ? null
              : [
                  <Button
                    key="pre"
                    ghost
                    onClick={() => handlePrev(selectedFile.id)}
                    style={{
                      border: 0,
                      fontSize: 30,
                      verticalAlign: 'middle',
                      height: 'fit-content',
                    }}
                  >
                    <LeftCircleOutlined />
                  </Button>,
                  <span style={{ color: 'white' }}>
                    {selectedFile.id}/{numberOfFile}
                  </span>,
                  <Button
                    key="next"
                    ghost
                    onClick={() => handleNext(selectedFile.id)}
                    style={{
                      border: 0,
                      fontSize: 30,
                      verticalAlign: 'middle',
                      height: 'fit-content',
                    }}
                  >
                    <RightCircleOutlined />
                  </Button>,
                ]
          }
        >
          <FileViewer
            key={selectedFile.id}
            fileType={fileType ? fileType.toLowerCase() : fileType}
            filePath={URL}
            errorComponent={<Empty />}
          />
        </CustomModal>
      )}
    </>
  )
}

export default withTranslation()(FilePreviewModal)
