import axios from 'axios'
import path from 'path'

require('dotenv').config()

const getUUID = () => localStorage.getItem('uuid')

const authInterceptor = async config => {
  console.log(config)
  const login = localStorage.getItem('login')
  if (login === '1') {
    const redirectURL = 'https://ac-data.info'
    const origin = 'https://accounts.freebieac.com'
    const globalSessionToken = sessionStorage.getItem('global_session_id')
    if (globalSessionToken !== null) {
      await fetch(`${origin}/get-session-user?globalSessionToken=${globalSessionToken}`)
        .then(ress => ress.json())
        .then(response => {
          const ssoUserLoggedin = response.sessionUser ? response.sessionUser : null
          if (!ssoUserLoggedin) {
            sessionStorage.clear()
            const link = document.createElement('a')
            link.href = '/'
            document.body.appendChild(link)
            return link.click()
          }
          sessionStorage.setItem('sessionUserAvatar', response.sessionUser.avatar)
          sessionStorage.setItem('sessionUserEmail', response.sessionUser.email)
          sessionStorage.setItem('sessionUserName', response.sessionUser.nick_name)
          sessionStorage.setItem('sessionUserId', response.sessionUser.user_id)
          sessionStorage.setItem('sessionUserPremium', response.sessionUser.premium_info)
          return true
        })
    }

    const ssoUser = sessionStorage.getItem('sessionUserId')
    const ACdataUser = localStorage.getItem('uuid')

    // ssoUser logged in && have ACdata uuid
    if (ssoUser !== null && ACdataUser !== null) {
      console.log('USERSSSSSSSSSS: ssoUser logged in && HAVEEEEEEEE ACdata uuid')
      await fetch(`${process.env.REACT_APP_API_REQUEST}/sso`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ sso: ssoUser }),
      })
        .then(ress => ress.json())
        .then(async response => {
          if (response.uuid === '') {
            await fetch(`${process.env.REACT_APP_API_REQUEST}/sso/update?id=${ACdataUser}`, {
              method: 'POST',
              mode: 'cors',
              body: JSON.stringify({ sso: ssoUser }),
            })
          } else {
            localStorage.setItem('uuid', response.uuid)
          }
        })
    }

    // ssoUser logged in && no ACdata uuid
    else if (ssoUser !== null && ACdataUser === null) {
      console.log('USERSSSSSSSSSS: ssoUser logged in && NOOOOOO ACdata uuid')
    }

    // ssoUser NOOO login && have ACdata uuid
    else if (ssoUser === null && ACdataUser !== null) {
      console.log('USERSSSSSSSSSS: ssoUser NOOO login && have ACdata uuid')
    }

    // new Userrrrrrr
    else if (ssoUser === null && ACdataUser === null && localStorage.getItem('cloud') !== '1') {
      console.log('USERSSSSSSSSSS: NEWWWWWW User')
      const link = document.createElement('a')
      link.href = `https://accounts.freebieac.com/login?serviceURL=${redirectURL}`
      document.body.appendChild(link)
      return link.click()
    }
    return config
  }
  return config
}

export const createShortCut = (data, uuid) => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/shortcut/create?id=${uuid}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getUserBySSOId = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/sso`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const updateUserSSOId = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/sso/update?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const searchFileFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/search?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkOriginFile = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/shortcut/file?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkOriginFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/shortcut/folder?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkDuplicateFile = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/file/duplicate?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkDuplicateFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder/duplicate?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const renameFile = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/file/rename?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const renameFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder/rename?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const moveFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder/move?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const moveFile = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/file/move?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const uploadBigSizeNotify = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/notify?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const startUpload = (data, cancel) => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/part/start`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      return e
    })
}

export const startUploadFolder = (data, cancel) => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/folder/part/start?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      return e
    })
}

export const getPartUrl = (data, cancel) => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/part/url?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      throw e
    })
}

export const completeUpload = (data, cancel) => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/part/complete?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      throw e
    })
}

export const feedback = (message, gRecaptcha) => {
  const formData = new FormData()
  formData.set('message', message)
  formData.set('g-recaptcha-response', gRecaptcha)
  return axios
    .post('https://ac-data.info/feedback', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      // console.log(res)
      return 'success'
    })
    .catch(e => {
      // console.log(e)
      return 'fail'
    })
}

export const updateFilesExpiry = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/expiry/update?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const updateSharingPassword = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/password/update?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const zipFiles = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST_OLD}/file/zip?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getCurrentStorage = () => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/storage?id=${getUUID()}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getFileUrlForView = (userId, data) => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/file/view?id=${userId}`, data)
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFileUrl = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/file/url`, data)
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFilesForever = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/forever?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFoldersForever = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/forever/folder?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const restoreFiles = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/restore?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const restoreFolders = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/restore/folders?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getDeletedFiles = () => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/browse/deleted?id=${getUUID()}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const verifyEmail = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/verify/email`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifyCode = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/verify/code`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFolderStructure = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const createFolderNewUser = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder/new`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const createFolderExistedUser = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/folder/user?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const getPreSignedUrls = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const getPreSignedUrlByFolder = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/folder?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFromURL = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/direct`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFromURLExistUser = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/folder/direct?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFile = ({ file, signedUrl, type, cancel, handlePercent }) => {
  const options = {
    headers: { 'Content-Type': file.type },
    cancelToken: cancel,
    onUploadProgress: progressEvent => {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      handlePercent(file.name, percent)
    },
  }
  return axios
    .put(signedUrl, file, options)
    .then(res => {
      return res.status
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      return e
    })
}

export const uploadFolder = file => {
  const formData = new FormData()
  formData.append('file', file)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/upload/folder`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(e => {
      // console.log(e)
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const postSaveFiles = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/save?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const deleteFiles = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/files?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSavedFiles = folder => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/browse?id=${getUUID()}`, folder, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      // console.log(res.data)
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFolder = data => {
  axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/delete/folder?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => {
      // console.log(e)
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const shareFiles = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/share/files?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const shareFolder = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/share/folder?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkPasswordShareFile = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/password`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const updateDownloadTime = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/download`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFileInformation = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/verify/file`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifySharedFolders = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/share/verify`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifySharedPassword = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/share/password`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSharedFolders = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST}/share`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSharedDownloadURL = data => {
  return axios
    .post(`${process.env.REACT_APP_API_REQUEST_OLD}/download/shared`, data)
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const downloadFile = async file => {
  // window.open(file.url)
  // return axios({
  //   url: file.url,
  //   method: 'GET',
  //   responseType: 'blob', // important
  //   onDownloadProgress: progressEvent => {
  //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //     handlePercent(percentCompleted)
  //   },
  // }).then(response => {
  // console.log(response)
  // const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = file.url
  link.setAttribute('download', file.name)
  document.body.appendChild(link)
  link.click()
  // })
  // openInNewTab(file.url)
}

function openInNewTab(url) {
  const win = window.open(url, '_blank')
  win.focus()
  return 'success'
}

export default {
  verifyCode,
  verifyEmail,
  uploadFile,
  uploadFolder,
  uploadFromURL,
  uploadFromURLExistUser,
  deleteFolder,
  deleteFiles,
  shareFiles,
  shareFolder,
  checkPasswordShareFile,
  downloadFile,
  getFolderStructure,
  createFolderExistedUser,
  createFolderNewUser,
  getDeletedFiles,
  deleteFilesForever,
  deleteFoldersForever,
  restoreFiles,
  restoreFolders,
  getFileUrl,
  getFileUrlForView,
  updateDownloadTime,
  getCurrentStorage,
  zipFiles,
  updateSharingPassword,
  feedback,
  updateFilesExpiry,
  startUpload,
  startUploadFolder,
  getPartUrl,
  completeUpload,
  uploadBigSizeNotify,
  getSharedFolders,
  verifySharedFolders,
  verifySharedPassword,
  getSharedDownloadURL,
  checkOriginFile,
  checkOriginFolder,
  searchFileFolder,
}
