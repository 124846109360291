/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { DownloadOutlined, FileOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, Progress } from 'antd'
import { formatDistance, addHours, parse } from 'date-fns'
import { ja } from 'date-fns/locale'
import { format } from 'date-fns/esm'

import {
  checkPasswordShareFile,
  downloadFile,
  updateDownloadTime,
  getFileInformation,
  getFileUrl,
} from 'services/upload'
import { decryptCredential, extractFileInformation } from 'utils/download.util'
import { openNotificationWithIcon } from 'utils/common.util'

class DownloadFile extends React.Component {
  state = {
    redirect: false,
    shared: '',
    password: '',
    error: '',
    status: '',
    percent: -1,
    // fileInformation: {},
    loading: false,
    fileExisted: true,
    fileDetails: {
      LastModified: format(addHours(new Date(), 24), 'yyyy-MM-dd hh:mm:ss'),
      Size: '',
      Name: '',
      Path: '',
      Downloaded: 0,
      Protected: false,
      ExpiredDate: format(addHours(new Date(), 24), 'yyyy-MM-dd hh:mm:ss'),
    },
  }

  // eslint-disable-next-line consistent-return
  async componentDidMount() {
    const {
      match: { params },
    } = this.props
    const { metadata } = params
    if (metadata) {
      this.setState({
        shared: metadata.length > 30 ? metadata : `${metadata.replace(/\&/g, '/')}=`,
      })
      const data = {
        shared: metadata.length > 30 ? metadata : `${metadata.replace(/\&/g, '/')}=`,
      }
      const fetchFilesDetails = await getFileInformation(data)

      if (!fetchFilesDetails.fileExisted) {
        this.setState({
          fileExisted: false,
        })
        setTimeout(() => this.setState({ redirect: true }), 5000)
        return false
      }
      return this.setState({
        fileExisted: true,
        fileDetails: fetchFilesDetails.fileDetails[0],
      })
    }
  }

  handlePercent = currentPercent => {
    this.setState({ percent: currentPercent })
  }

  processDownloadFile = async () => {
    const { t } = this.props
    const { shared, fileDetails } = this.state
    const data = {
      url: await getFileUrl({ shared }),
      name: fileDetails.Name,
    }
    await downloadFile(data, this.handlePercent)
      .then(res => {
        this.setState({ status: 'success' })
        openNotificationWithIcon('success', t('download_files_success'))
        this.processUpdateDownloadTime()
      })
      .catch(e => {
        // console.log('e ', e)
        openNotificationWithIcon('error', t('download_files_fail'))
      })
      .finally(() => {
        this.setState({ password: '' })
        // this.setState({ loading: false, password: '' })
      })
  }

  processUpdateDownloadTime = () => {
    const { shared } = this.state
    updateDownloadTime({ shared, type: 'file' })
  }

  handleDownloadFile = async () => {
    const { shared, password, fileDetails } = this.state
    const { t } = this.props
    this.setState({ loading: true, percent: 0 })
    if (+fileDetails.Downloaded >= 20) {
      openNotificationWithIcon('error', t('maximum_downloaded'))
      return this.setState({ loading: false })
    }
    if (fileDetails.Protected) {
      const data = {
        shared,
        password,
      }
      const result = await checkPasswordShareFile(data)
      if (result.checkPassword) {
        return this.processDownloadFile()
      }
      return this.setState({ error: t('invalid_password'), loading: false })
    }
    return this.processDownloadFile()
  }

  render() {
    const {
      password,
      loading,
      error,
      status,
      fileExisted,
      fileDetails,
      percent,
      redirect,
    } = this.state
    const { t } = this.props
    const expiryTime = addHours(
      parse(fileDetails.ExpiredDate, 'yyyy-MM-dd HH:mm:ss', new Date()),
      9,
    )
    const currentDate = new Date()

    return (
      <div className="container">
        <Helmet title={t('download_files_tittle')} />
        <div className="row justify-content-md-center mt-5">
          <div className="col">
            {fileExisted && currentDate < expiryTime ? (
              <div className="card">
                {/* <div className="card-header">
                  {t('ac_data')} {t('download_files_tittle')}
                </div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="card-text">
                        <div className="d-flex align-items-center mb-4">
                          <FileOutlined style={{ fontSize: 50 }} />
                          <div className="ml-2">
                            <h2>{fileDetails.Name}</h2>
                          </div>
                        </div>
                        <p>
                          <strong>{t('file_size')}:</strong> {fileDetails.Size}
                        </p>
                        <p>
                          <strong>{t('list_file_expired')}:</strong>{' '}
                          {format(expiryTime, 'yyyy-MM-dd HH:mm')}
                        </p>
                        <p>
                          <strong>{t('file_downloaded_times')}:</strong> 残り
                          {20 - +fileDetails.Downloaded}回
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column  mx-auto justify-content-center">
                      {fileDetails.Protected && (
                        <React.Fragment>
                          {error && <Alert message={error} type="error" closable />}
                          {status !== 'success' && (
                            <React.Fragment>
                              <label htmlFor="password" />
                              {t('file_download_password')}
                              <Input.Password
                                id="password"
                                size="large"
                                value={password}
                                placeholder=""
                                className="mb-3 mt-2"
                                onChange={e =>
                                  this.setState({ password: e.target.value, error: '' })
                                }
                              />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      <Button
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        // icon={percent === -1 ? 'download' : ''}
                        size="large"
                        // loading={loading}
                        onClick={() => this.handleDownloadFile()}
                        disabled={loading}
                        // disabled={(file.isProtected && !password) || status === 'success'}
                      >
                        {/* {percent >= 0 && (
                          <Progress
                            type="circle"
                            // className={percent < 100 ? 'white-text' : ''}
                            percent={percent}
                            width={30}
                            style={{ paddingRight: '10px' }}
                            strokeColor={{
                              '0%': '#87d068',
                              '100%': '#108ee9',
                            }}
                          />
                        )} */}
                        <span>
                          {status === 'success' ? t('downloading_button') : t('download_button')}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="card-footer text-muted">
                  {formatDistance(addHours(expiryTime, 9), new Date(), { locale: ja })}
                </div> */}
              </div>
            ) : (
              <Result status="404" title={t('file_deleted')} />
            )}
            {redirect ? <Redirect to="/" /> : ''}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(DownloadFile)
