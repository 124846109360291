import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import AcProduct from 'components/layout/AcProduct'
import TopBar from 'components/layout/TopBar'
import Footer from 'components/layout/Footer'
import { isMacOS } from 'utils/common.util'

@withRouter
class ShareLayout extends React.PureComponent {
  render() {
    const { t, children, i18n } = this.props
    return (
      <Layout>
        <AcProduct lang={i18n.language} />
        <Layout.Header>
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ position: 'relative', width: '68vw' }} className="mr-auto ml-auto">
          <div>{children}</div>
        </Layout.Content>
        <div
          className="row bookmark-description"
          style={{ padding: '40px 0 50px 0', justifyContent: 'center' }}
        >
          {t('bookmark_if')}
          <div className="bookmark-key">{isMacOS() ? 'Cmd ⌘' : 'Ctrl'}</div> +{' '}
          <div className="bookmark-key">D</div>
          {t('bookmark_then')}
        </div>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    )
  }
}

export default withTranslation()(ShareLayout)
