import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import axios from 'axios'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class AcProduct extends React.Component {
  state = {
    headerData: null,
    currentLang: '',
  }

  async componentDidMount() {
    const { lang } = this.props
    const response = await axios.get(`https://accounts.ac-illust.com/get-header?language=${lang}`)
    const { data } = response
    this.setState({ headerData: data, currentLang: lang })
  }

  shouldComponentUpdate(nextProps) {
    const { currentLang } = this.state
    return nextProps.lang !== currentLang
  }

  async componentDidUpdate(prevProps) {
    const { lang } = this.props
    const response = await axios.get(`https://accounts.ac-illust.com/get-header?language=${lang}`)
    const { data } = response
    const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm
    let match
    // eslint-disable-next-line no-cond-assign
    while ((match = re.exec(data))) {
      // // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
      try {
        // eslint-disable-next-line no-eval
        window.eval(match[1])
      } catch (err) {
        console.log('errrr ', err)
      }
    }
    if (lang !== prevProps.lang) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ headerData: data, currentLang: lang })
    }
  }

  render() {
    const { t } = this.props
    const { headerData } = this.state
    return (
      <div>
        <div
          className="common-freebie-render"
          style={{ minHeight: 38 }}
          dangerouslySetInnerHTML={{ __html: headerData }}
        />
        <style jsx global>
          {`
            .common-site-links {
              white-space: nowrap;
            }
            .dropdown-site-link .align-items-center {
              background: white !important;
            }
            .dropdown-site-link .align-items-center:hover {
              background: #f2f2f2 !important;
            }
            .common-site-links ul li .dropdown-site-link {
              font-weight: normal;
              height: max-content;
            }
            .common-site-links .border {
              border: 1px solid #dee2e6 !important;
              border-radius: 0.25rem !important;
            }
          `}
        </style>
      </div>
    )
  }
}

export default withTranslation()(AcProduct)
