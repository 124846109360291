import { notification } from 'antd'
import _path from 'path'
import crypto from 'crypto'

export function getSourceByName(name) {
  if (name === 'folder') return '/resources/images/icons/folder.png'
  const type = _path.extname(name)
  let src = '/resources/images/icons/file.png'
  switch (type) {
    case '.avi':
    case '.AVI':
      src = '/resources/images/icons/avi.png'
      break
    case '.ai':
    case '.AI':
      src = '/resources/images/icons/ai.png'
      break
    case '.doc':
    case '.DOC':
    case '.docx':
    case '.DOCX':
      src = '/resources/images/icons/doc.png'
      break
    case '.eps':
    case '.EPS':
      src = '/resources/images/icons/eps.png'
      break
    case '.gif':
    case '.GIF':
      src = '/resources/images/icons/gif.png'
      break
    case '.html':
    case '.HTML':
      src = '/resources/images/icons/html.png'
      break
    case '.jpeg':
    case '.JPEG':
    case '.jpg':
    case '.JPG':
      src = '/resources/images/icons/jpeg.png'
      break
    case '.mp3':
    case '.MP3':
      src = '/resources/images/icons/mp3.png'
      break
    case '.mp4':
    case '.MP4':
      src = '/resources/images/icons/mp4.png'
      break
    case '.pdf':
    case '.PDF':
      src = '/resources/images/icons/pdf.png'
      break
    case '.png':
    case '.PNG':
      src = '/resources/images/icons/png.png'
      break
    case '.ps':
    case '.PS':
      src = '/resources/images/icons/ps.png'
      break
    case '.psd':
    case '.PSD':
      src = '/resources/images/icons/psd.png'
      break
    case '.ppt':
    case '.PPT':
    case '.pptx':
    case '.PPTX':
      src = '/resources/images/icons/ppt.png'
      break
    case '.svg':
    case '.SVG':
      src = '/resources/images/icons/svg.png'
      break
    case '.txt':
    case '.TXT':
      src = '/resources/images/icons/txt.png'
      break
    case '.xls':
    case '.XLS':
    case '.xlsx':
    case '.XLSX':
      src = '/resources/images/icons/xls.png'
      break
    case '.zip':
    case '.ZIP':
      src = '/resources/images/icons/zip.png'
      break
    default:
      src = '/resources/images/icons/file.png'
  }
  return src
}

export function isMacOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (userAgent.indexOf('Mac') !== -1) return true
  return false
}

export function truncate(fullStr, strLen, force = false, separator) {
  if (strLen < 25 && force) strLen = 25
  if (fullStr.length <= strLen) return fullStr

  separator = separator || '..'

  const sepLen = separator.length
  const charsToShow = strLen - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
}

export function humanFileSize(size, round = false) {
  if (size === 0 || size === undefined) return '0B'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / 1024 ** i).toFixed(round ? 0 : 2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
}

export function copy(str) {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const htmlEntities = {
  /**
   * Converts a string to its html characters completely.
   *
   * @param {String} str String with unescaped HTML characters
   * */

  encode: str => {
    const buf = []

    // eslint-disable-next-line no-plusplus
    for (let i = str.length - 1; i >= 0; i--) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''))
    }

    return buf.join('')
  },
  /**
   * Converts an html characterSet into its original character.
   *
   * @param {String} str htmlSet entities
   * */
  decode: str => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec)
    })
  },
}

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message,
  })
}

// function encrypt3DES(data) {
//   const AC_DATA_ENCODE_URL_NEW = '.acdata.acworks.'
//   const md5Key = Buffer.from(AC_DATA_ENCODE_URL_NEW.substring(0, 8), 'utf-8')
//   const cipher = crypto.createCipheriv('des-cbc', md5Key, md5Key)
//   let encrypted = cipher.update(data, 'utf8', 'base64')
//   encrypted += cipher.final('base64')
//   return encrypted.replace('/', '&').slice(0, -1)
// }

function encrypt3DES(data) {
  const AC_DATA_ENCODE_URL_NEW = '.acdata.acworks.'
  const key = AC_DATA_ENCODE_URL_NEW + AC_DATA_ENCODE_URL_NEW.substring(0, 8)
  console.log('des', data, key)
  const md5key = Buffer.from(key, 'utf-8')
  const md5IV = Buffer.from(AC_DATA_ENCODE_URL_NEW.substring(0, 8), 'utf-8')
  const cipher = crypto.createCipheriv('des-ede3-cbc', md5key, md5IV)
  let encrypted = cipher.update(data, 'utf8', 'base64')
  encrypted += cipher.final('base64')
  return encrypted.replaceAll('/', '&').slice(0, -1)
}

export const encryptId = id => {
  return encrypt3DES(JSON.stringify(id))
}

export default {
  isMacOS,
  truncate,
  humanFileSize,
  htmlEntities,
  copy,
  encryptId,
  openNotificationWithIcon,
}
